.photo_popup_screen__overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  backdrop-filter: blur(10px);
  background-color: black;
  transition: all 400ms ease-in-out;
}

.photo_popup_screen__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 95%;
  transform: translateY(2.5%);
  margin: auto;
  border-radius: 10px;
}

.photo_popup_screen__container .close__button {
  position: absolute;
  top: 0;
  right: -1%;
  font-size: 0.7rem;
  font-weight: 500;
  width: 22.5px;
  height: 22.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
  color: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border-radius: 50px;
  background-color: var(--primary-green);
  cursor: pointer;
}

.photo_popup_screen__container img {
  max-height: 100%;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {

  .photo_popup_screen__container img {
    max-width: 100%;
    border-radius: 5px;
  }
  
}