/*  SECTION HOME  */

main {
    overflow-x: hidden;
}

.homepage__section {
    position: relative;
    height: 100vh;

    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.homepage__section .background_image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;  
}

.homepage__section__text {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(117, 117, 117, 0.4) 90%);    width: 100%;
    height: 100%;
    width: 100%;
    font-size: 2.5rem;
    font-weight: 600;
    display: none;
}

.homepage__section__text.visible {
    display: block;
}

.homepage__section__text
.text__row-1,
.text__row-2
{
    font-size: 2rem;
    font-weight: 900;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    line-height: 2.5rem;
    letter-spacing: 0.4rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.homepage__section__text
.text__row-1
{
    position: absolute;
    top: 290px;
    right: 50%;
    width: 60%;
    transform: translateX(50%);
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
}

.homepage__section__text
.text__row-2
{
    position: absolute;
    top: 340px;
    right: 50%;
    width: 60%;
    transform: translateX(50%);
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
}

.homepage__section__text .text__row-1.text-visible {
    opacity: 1;
}

.homepage__section__text .text__row-2.text-visible {
    opacity: 1;
}

.homepage__section .rotating-div {
    position: absolute;
    bottom: -220px;
    right: -80px;

    transform: rotate(-15deg);

    height: 300px;
    width: 700px;
    background-color: white;
}

.about__section,
.contact__section,
.assortment__section,
.projects__section,
.inspiration__section,
.rural_fences__section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10vh;
    min-height: 100vh;
}

.contact__section {
    min-height: 70vh;
}
/* !SECTION  */
    
/*  SECTION ABOUT  */

.quote {
    font-size: 1.5rem;
    font-weight: 400;
    font-style: italic;
    width: 50%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 80px;
}


@media screen and (max-width: 768px) {
    .quote {
        width: 80%;
    }
}
/* !SECTION  */


/*  SECTION PROJECTS  */

.projects__wrapper {
    width: 70%;
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    grid-gap: 20px;
}

@media screen and (max-width: 768px) {
    .projects__wrapper {
        width: 100%;
        grid-template: 1fr 1fr / 1fr;
    }

}
/* !SECTION  */


/*  SECTION ASSORTMENT  */

.assortment__wrapper {
    width: 80%;
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-top: 50px;
}

@media screen and (max-width: 768px) {
    .assortment__wrapper {
        width: 90%;
        grid-template: 1fr 1fr 1fr / 1fr;
    }

}
/* !SECTION  */


/*  SECTION RURAL FENCES  */

section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

section h3 {
    margin: 20px 0;
}

section ul {
    font-size: 1.2rem;
    font-weight: 400;
    width: 70%;
    margin-bottom: 40px;
}

.paragraph {
    font-size: 1.2rem;
    font-weight: 400;
    width: 70%;
    text-align: center;
    margin-bottom: 40px;
}

.image_grid {
    width: 90%;
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    grid-gap: 20px;
    margin: 50px 0;
}

.image_grid img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* !SECTION  */


/* SECTION INSPIRATION */

.inspiration__wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inspiration__nav {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.inspiration__nav_item {
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 0;
    border-bottom: 1px solid transparent;
    padding: 0;
    text-transform: uppercase;
}

.inspiration__nav_item:hover {
    color: var(--primary-green);
}

.inspiration__nav_item.selected {
    color: var(--primary-green);
}

.inspiration__item__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inspiration__item__description {
    font-size: 0.9rem;
    font-weight: 400;
    width: 80%;
    text-align: center;
    margin: 25px 0;
}

.inspiration__item__images {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.inspiration__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(0.1);
    opacity: 0;
    transition: all 0.25s ease-out;
    cursor: pointer;
}

.inspiration__image.show {
    transform: scale(1);
    opacity: 1;
}

.image__column1 {
    gap: 15px;
    display: grid;
    grid-template: 0.3fr / 47.5vw;
}

.image__column2 {
    gap: 15px;
    display: grid;
    grid-template: 0.7fr / 47.5vw;
}
/* !SECTION  */


@media screen and (max-width: 768px) {
    .homepage__section__text
    .text__row-1,
    .text__row-2
    {
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.5rem;
        letter-spacing: 0.4rem;
    }
 
    .homepage__section__text
    .text__row-1
    {
        position: absolute;
        top: 220px;
        right: 50%;
        width: 70%;
        transform: translateX(50%);
        opacity: 0;
        transition: opacity 1000ms ease-in-out;
        font-size: 1.25rem;
        letter-spacing: 0.3rem;
    }

    .homepage__section__text
    .text__row-2
    {
        position: absolute;
        top: 265px;
        right: 50%;
        width: 70%;
        transform: translateX(50%);
    }

    .image_grid {
        width: 100%;
        grid-template: 1fr 1fr / 1fr;
    }

    .image_grid img {
        border-radius: 0;
    }

    section ul {
        width: 90%;
    }

    .paragraph {
        width: 90%;
    }

    .inspiration__wrapper {
        width: 100%;
    }

    .inspiration__item__images {
        flex-direction: column;
    }

    .image__column1,
    .image__column2 {
        grid-template: auto / 1fr;
    }

    .inspiration__item__description {
        width: 90%;
    }

    .inspiration__nav {
        display: grid;
        grid-template: 1fr / 1fr 1fr;
        width: 90%;
    }

    .inspiration__nav_item {
        text-align: center;
        padding: 2.5px;
    }
  }








