.footer__container {
  min-height: 7.5vh;
  padding: 17.5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__container a {
  text-decoration: none;
  color: var(--primary-font-color);
}

.footer__container div {
  padding: 0 20px;
}

@media screen and (max-width: 768px) {
  .footer__container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 7.55px;
    margin-bottom: 15px;
  } 
/* 
  .copyright {
    grid-column: 1 / span 2;
  } */

  .footer__container div {
    display: none;
  }
}