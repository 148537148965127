.project__container {
  position: relative;
  aspect-ratio: 1/1;
  overflow: hidden;
}

.project__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.project__image.hovered {
  filter: brightness(0.5);
}

.project__details__container {
  position: absolute;
  bottom: 10px;
  left: 0;
  height: 30%;
  background-color: var(--primary-green);
  color: white;
  overflow: hidden;
  padding: 20px;
  font-size: 1vw;
  line-height: 1.7vw;
  border-radius: 5px;
  width: 100%;

  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.project__container:hover .project__details__container {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .project__details__container {
    font-size: 3vw;
    line-height: 4vw;
  }
  .project__image {
    border-radius: 0;
  }

}

