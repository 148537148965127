* {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  font-family: playfair display, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: rgb(54, 54, 54);
}

body::-webkit-scrollbar {
  display: none;
}

:root {
  --primary-green: #636C41;
  --primary-font-color: #363636;
  --primary-font-color--light: #5a5a5a;
}

h1 {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 30px;
}

.sub-title-text {
  width: 70%;
  text-align: center;
  line-height: 1.4rem;
}

.image__fade_in--left {
  transition: all 0.75s ease-out;
  opacity: 0;
  transform: translateX(-15px);
}

.image__fade_in--right {
  transition: all 0.75s ease-out;
  opacity: 0;
  transform: translateX(15px);
}

.hide {
  display: none;
}

.visible {
  opacity: 1;
  transform: translateX(0);
}


@media screen and (max-width: 768px) {
  .sub-title-text {
      width: 90%;
  }

}
