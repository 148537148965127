.configurator__container {  
  margin-top: 20vh;
  width: 100vw;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.configurator_page {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  height: 100%;
  min-width: 100%;
}

.configurator__container .popup__window {
  position: absolute;
  width: 80%;
  height: 70%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 50;
  border-radius: 20px;
  opacity: 0;
  transition: all 500ms ease-in-out;
  padding: 30px;
  overflow: hidden;
}

.configurator__container .popup__window.active {
  opacity: 1;
}

.configurator__container .popup__window .popup__close_icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 17.5px;
  top: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  cursor: pointer;
}

.configurator__container .popup__window .popup__title{
  width: 100%;
  text-align: left;
  margin-bottom: 30px;
  font-size: 1.3rem;
  font-weight: 600;
}

.configurator__container .popup__window .popup__text{
  width: 90%;
  font-size: 0.85rem;
}

.configurator__container .popup__window .popup__text li{
  margin-bottom: 7.5px;
}

.configurator__container .popup__window .popup__text li b{
  color: var(--primary-green);
}

.configurator__container .popup__window button svg {
  font-size: 3rem;
  color: white;
}

.configurator__container .popup__window section {
  display: block;
  margin-bottom: 10px;
}

.configurator__container .popup__window .back_button {
  position: absolute;
  z-index: 100;
  left: 30px;
  bottom: 25%;
  transform: translateY(50%);
  background-color: transparent;
  border: none;
  height: 70px;
  cursor: pointer;
}

.configurator__container .popup__window .forward_button {
  position: absolute;
  z-index: 100;
  right: 30px;
  bottom: 25%;
  transform: translateY(50%);
  background-color: transparent;
  border: none;
  height: 70px;
  cursor: pointer;
}

.configurator__container .popup__window .popup__images{
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth; /* Add smooth scroll behavior */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}

.configurator__container .popup__window .popup__images::-webkit-scrollbar {
  display: none;
}

.configurator__title {
  width: 100%;
  text-align: center;
  font-size: 1.7rem;
  font-weight: 700;
  margin: 40px;
  color: var(--primary-font-color--light);

}

.configurator_pages__container {
  position: relative;
  width: 80%;
  height: 70%;
  display: flex;
  transition: all 500ms ease-in-out;
}

.back_button--pages {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  border: none;
  color: var(--primary-font-color--light);
  cursor: pointer;
  z-index: 100;
  margin-top: 25px;
}

.back_button--pages.hide {
  display: none;
}

.choices__wrapper {
  display: grid;
  grid-template: auto / 1fr 1fr;
  min-width: 60%;
  height: fit-content;
  gap: 50px;
}

@media screen and (max-width: 768px) {
  .choices__wrapper {
    display: grid;
    grid-template: auto / 1fr;
    min-width: 90%;
    height: fit-content;
    gap: 50px;
  }

  .configurator__title {
    margin-top: 0;
  }

  .configurator_pages__container{
    width: 100%;
  }

  .configurator_page{
    width: 100%;
  }

  .configurator__container .popup__window {
    position: absolute;
    width: 95%;
    min-height: 80%;
  }

  .configurator__container .popup__window .popup__text{
    overflow: scroll;
    max-height: 45%;
    width: 100%;
  }

  .configurator__container .popup__window .popup__images{
    max-height: 40%;
  }
}

